<template>
  <b-overlay
    :show="ShowOverlay"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>

      <!-- Table here -->
      <div class="card">
        <div class="d-flex justify-content-between align-item-center">
          <router-link :to="{name: 'create-agent'}">
            <button class="btn btn-gradient-primary mx-1 my-2">
              <i class="fa fa-plus" /> Add new agent
            </button>
          </router-link>

          <div class="mx-1 my-2">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="hi-first-name"
                v-model="login_name"
                placeholder="Enter Login Name"
                @input="FilterName(login_name)"
              />
            </b-input-group>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th style="width: 1%">
                  No.
                </th>
                <th style="width: 5%">
                  Position
                </th>
                <th style="width: 10%; text-align: left">
                  Login Name
                </th>
                <th style="width: 10%; text-align: left">
                  Name
                </th>
                <th style="width: 8%; text-align: center">
                  Currency
                </th>
                <th style="width: 8%; text-align: center">
                  Credit
                </th>
                <!-- <th style="width: 8%; text-align: center">
                  Level
                </th> -->
                <th style="width: 8%">
                  Web name
                </th>
                <th style="width: 10%; text-align: left">
                  Upline
                </th>
                <th style="width: 10%">
                  Create Date
                </th>
                <th style="width: 10%">
                  Last login date
                </th>
                <th style="width: 8%; text-align: left">
                  Last login IP
                </th>
                <th style="width: 5%">
                  Status
                </th>
                <th style="width: 5%">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(Items,Index) in GrandRow.slice((currentPage - 1) * perPage, currentPage * perPage)"
                :key="Index"
              >
                <td>{{ perPage * (currentPage - 1) + (Index + 1) }}</td>
                <td>
                  <span>{{ Items.role === 0 ? 'Agent': Items.role === 1 ? 'Master' : 'Company' }}</span>
                </td>
                <td style="text-align: left">
                  <router-link
                    :to="{ name: 'edit-agent', params: { ID:Items.id }}"
                    class="overflow"
                  ><span class="link">{{ Items.username }}</span></router-link>
                </td>
                <td style="text-align: left">
                  {{ Items.fristname }} {{ Items.lastname }}
                </td>
                <td
                  class="parentCell"
                  style="text-align: left"
                >
                  <div class="overflow-text">
                    <span>THB </span>
                  </div>
                </td>
                <td style="text-align: right">
                  <span>0.00</span>
                </td>
                <!-- <td style="text-align: center">
                  <span>Starter</span>
                </td> -->
                <td>
                  <span>{{ Items.web_name }}</span>
                </td>
                <td style="text-align: left">
                  <span>{{ Findname(Items.upline) }}</span>
                </td>
                <td style="white-space: nowrap">
                  {{ ConvertDate(Items.created_at) }}
                </td>
                <td style="white-space: nowrap">
                  {{ ConvertDate(Items.last_login) }}
                </td>
                <td style="text-align: left">
                  <span>{{ Items.last_ip }}</span>
                </td>
                <td>{{ Items.status === 1 ? 'Active' : 'Locked' }}</td>
                <td>
                  <router-link :to="{ name: 'edit-agent', params: { ID:Items.id }}">
                    <i class="fas fa-pencil-alt" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Pagination here -->
        <div class="demo-spacing-0 mx-1 my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="GrandRow.length"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BPagination,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BPagination,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      ShowOverlay: false,
      currentPage: 1,
      perPage: 50,
      rows: 200,
      activeBtn: 'btn2',
      login_name: '',
      s_date: '',
      t_date: '',
      product: 'All',
      proOption: [],
      GrandRow: [],
    }
  },
  mounted() {
    this.GetAgent()
  },
  methods: {
    async GetAgent() {
      try {
        const { data: Res } = await this.$http.get(
          'https://reportapi.power100th.com/api/auth/aglist',
        )
        this.GrandRow = Res
      } catch (error) {
        console.log(error)
      }
    },
    Findname(x) {
      const y = this.GrandRow.find(e => e.id === x)
      if (y) {
        return y.username
      }
      return '-'
    },
    ConvertDate(x) {
      if (x) {
        const y = moment(x).tz('Asia/Bangkok').format('DD-MM-YYYY HH:mm:ss')
        return y
      }
      return '-'
    },
    Commas(x) {
      if (x) {
        // console.log(x)
        // console.log(Math.round(x) !== x)
        if (Math.round(x) !== x) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
}
</script>

<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
